import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useAreaStore = defineStore("area", () => {
  const area = ref({
    id: null,
    name: "",
    story: {
      id: null,
      name: "",
    },
    building: {
      id: null,
      name: "",
    },
    company: {
      id: null,
      name: "",
    },
    area_type: {
      activity_sla: [],
    },
    activity_type_id: 0,
    rate_cleaning: 0,
  });
  const name_area = computed(() => area.value.name);
  const rate = computed(() => area.value.rate_cleaning);
  const id_area = computed(() => area.value.id);
  const floor = computed(() => area.value.story?.name ?? "Sin Piso");
  const building = computed(() => area.value.building.name);
  const customer = computed(() => area.value.company.name);
  const activities = computed(() => area.value.area_type.activity_sla);
  const building_id = computed(() => area.value.building.id);
  const story_id = computed(() => area.value.story?.id);
  const company_id = computed(() => area.value.company.id);

  const saveToLocalStorage = () => {
    localStorage.setItem("area:current", JSON.stringify(area.value));
  };

  const restoreArea = () => {
    const storedArea = JSON.parse(localStorage.getItem("area:current"));

    if (storedArea) area.value = storedArea;
  };

  restoreArea();
  return {
    area,
    rate,
    floor,
    building,
    customer,
    name_area,
    activities,
    id_area,
    building_id,
    company_id,
    story_id,
    saveToLocalStorage,
  };
});
